<template>
  <div>
    <transition name="fade-and-scale">
      <Formulaire v-if="get_state" />
    </transition>
    <div id="home-container"  data-scroll-section ref="scrollSections">
      <div id="home">
        <LanguageSwitcher :languageSwitcher="languageSwitcher" />
        <Hero :hero="hero" />
        <GameEntry :gameEntry="gameEntry" />
        <ChallengeEntry :challengeEntry="challengeEntry" />
        <Recipe :recipe="recipe" />
        <Footer :footer="footer" />
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import Formulaire from '@/components/Formulaire.vue'
  import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
  import Hero from '@/components/Hero.vue'
  import GameEntry from '@/components/GameEntry.vue'
  import ChallengeEntry from '@/components/ChallengeEntry.vue'
  import Recipe from '@/components/Recipe.vue'
  import Footer from '@/components/Footer.vue'
  import axios from 'axios'
  import locomotiveScroll from "locomotive-scroll";

  export default {
    name: "locoScroll",
    data() {
      return {
        languageSwitcher: "",
        hero: "",
        gameEntry: {
          title: "",
          cta: {
            text: "",
            url: ""
          }
        },
        challengeEntry: {
          title: "",
          cta: {
            text: ""
          }
        },
        recipe: {
          aside: {
            title: "",
            desc: ""
          },
          title: "",
          desc: "",
          cta: {
            text: "",
            url: ""
          }
        },
        footer: {
          pretitle: "",
          title: "",
          socials: {
            facebook: "",
            instagram: ""
          },
          website: {
            text: "",
            url: ""
          },
          links: {
            link1: {
              text: "",
              url: ""
            },
            link2: {
              text: "",
              url: ""
            },
            link3: {
              text: "",
              url: ""
            }
          },
          last: ""
        },
        scrollIns: null
      }
    },
    computed: {
      get_state(){
        if(this.$store.state.display_form_popup){
          this.stopLocoScroll();
        } else {
          this.startLocoScroll();
        }
        return this.$store.state.display_form_popup;
      }
    },
    methods: {
      initLocoScroll() {
        const _self = this;
        this.scroll = new locomotiveScroll({
          el: _self.$refs['scrollSections'],
          smooth: true,
          getDirection: true,
          mobile: {
            breakpoint: 0,
            smooth: false,
            getDirection: true,
          },
          tablet: {
            breakpoint: 0,
            smooth: false,
            getDirection: true,
          }
        });
      },
      startLocoScroll() {
        const _self = this;
        this.scroll.start();
      },
      stopLocoScroll() {
        const _self = this;
        this.scroll.stop();
      },
      updateLocoScroll() {
        const _self = this;
        this.scroll.update();
      },
      load_data() {
        const host = window.location.host;
        const lang = host.indexOf("bouillon") != -1 ? "fr" : "en";

        var dictionnary = './data/fr.json';
        if(lang == "en"){
          var dictionnary = './data/en.json';
        }

        axios.get(dictionnary)
          .then(response => {
              this.languageSwitcher = response.data.languageSwitcher;
              this.hero = response.data.hero;
              this.gameEntry = response.data.gameEntry;
              this.challengeEntry = response.data.challengeEntry;
              this.recipe = response.data.recipe;
              this.footer = response.data.footer;
          })
          .catch((err) => {
              console.error(err);
          });
      }
    },
    created() {
        this.load_data();
    },
    mounted() {
      const _self = this;
      this.$nextTick(function() {
        _self.initLocoScroll();
        this.scroll.update();
        setTimeout(() => {
          this.scroll.update();
        }, 100)
      });

      window.addEventListener('load', () => {
          this.scroll.update();
          window.scrollTo(0, 0);
      })
    },
    unmounted(){
        this.scroll.stop();
    },
    components: {
      Formulaire,
      LanguageSwitcher,
      Hero,
      GameEntry,
      ChallengeEntry,
      Recipe,
      Footer
    }
  }
</script>

<style scoped>
    #home-container,
    #home{
      overflow: hidden;
    }

    #home-container{
      transition: opacity ease 0.5s;
    }

    .fade-and-scale-enter-from {
        opacity: 0;
        transform: scale(0.95);
    }
    .fade-and-scale-enter-to,
    .fade-and-scale-leave-from {
        opacity: 1;
        transform: none;
    }
    .fade-and-scale-leave-to {
        opacity: 0;
        transform: scale(0.95);
    }
    .fade-and-scale-enter-active,
    .fade-and-scale-leave-active {
        transition: all ease 0.4s;
    }

    @media screen and (min-width: 992px){
      #form-component.fade-and-scale-enter-from,
      #form-component.fade-and-scale-leave-to {
          transform: translate(-50%, -50%) scale(0.95);
      }
      #form-component.fade-and-scale-enter-to,
      #form-component.fade-and-scale-leave-from {
          transform: translate(-50%, -50%);
      }
    }

    @media screen and (min-width: 992px) and (max-height: 800px){
      .fade-and-scale-enter-from,
      .fade-and-scale-leave-to {
          transform: translateX(-50%) scale(0.95);
      }
      .fade-and-scale-enter-to,
      .fade-and-scale-leave-from {
          transform: translateX(-50%) ;
      }
    }
</style>