<template>
    <div v-if="type == 'external' && size == 'small'">
        <a :href="cta.url" class="btn" :class="color" target="_blank">
            <span>{{ cta.text }}</span>
            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3526 0H8.87415C8.51512 0 8.2241 0.294652 8.2241 0.658153C8.2241 1.02165 8.51513 1.31631 8.87415 1.31631H11.8553L5.47571 7.77568C5.22164 8.03265 5.22164 8.44949 5.47571 8.70659C5.60268 8.83514 5.76905 8.89941 5.93528 8.89941C6.10153 8.89941 6.26804 8.83514 6.39486 8.70659L12.7025 2.32031V5.1924C12.7025 5.5559 12.9936 5.85055 13.3526 5.85055C13.7116 5.85055 14.0026 5.5559 14.0026 5.1924L14.0028 0.65852C14.0028 0.294882 13.7118 0.000233763 13.3527 0.000233763L13.3526 0Z" fill="white"/>
                <path d="M11.1053 6.63043C10.7463 6.63043 10.4552 6.92509 10.4552 7.28859V12.4814C10.4552 12.7193 10.2568 12.9202 10.0218 12.9202H1.73321C1.4982 12.9202 1.29978 12.7193 1.29978 12.4814V4.0894C1.29978 3.85146 1.49821 3.65056 1.73321 3.65056H6.88016C7.23919 3.65056 7.53022 3.3559 7.53022 2.99239C7.53022 2.62889 7.23919 2.33423 6.88016 2.33423H1.73354C0.777664 2.33423 0 3.1216 0 4.0894V12.4814C0 13.4492 0.777664 14.2366 1.73354 14.2366H10.0221C10.978 14.2366 11.7557 13.4492 11.7557 12.4814L11.7555 7.28859C11.7555 6.92522 11.4645 6.63043 11.1055 6.63043H11.1053Z" fill="white"/>
            </svg>
        </a>
    </div>
    <div v-else-if="type == 'external' && size == 'large'">
        <a :href="cta.url" class="btn btn-large" :class="color" target="_blank">
            <span>{{ cta.text }}</span>
            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3526 0H8.87415C8.51512 0 8.2241 0.294652 8.2241 0.658153C8.2241 1.02165 8.51513 1.31631 8.87415 1.31631H11.8553L5.47571 7.77568C5.22164 8.03265 5.22164 8.44949 5.47571 8.70659C5.60268 8.83514 5.76905 8.89941 5.93528 8.89941C6.10153 8.89941 6.26804 8.83514 6.39486 8.70659L12.7025 2.32031V5.1924C12.7025 5.5559 12.9936 5.85055 13.3526 5.85055C13.7116 5.85055 14.0026 5.5559 14.0026 5.1924L14.0028 0.65852C14.0028 0.294882 13.7118 0.000233763 13.3527 0.000233763L13.3526 0Z" fill="white"/>
                <path d="M11.1053 6.63043C10.7463 6.63043 10.4552 6.92509 10.4552 7.28859V12.4814C10.4552 12.7193 10.2568 12.9202 10.0218 12.9202H1.73321C1.4982 12.9202 1.29978 12.7193 1.29978 12.4814V4.0894C1.29978 3.85146 1.49821 3.65056 1.73321 3.65056H6.88016C7.23919 3.65056 7.53022 3.3559 7.53022 2.99239C7.53022 2.62889 7.23919 2.33423 6.88016 2.33423H1.73354C0.777664 2.33423 0 3.1216 0 4.0894V12.4814C0 13.4492 0.777664 14.2366 1.73354 14.2366H10.0221C10.978 14.2366 11.7557 13.4492 11.7557 12.4814L11.7555 7.28859C11.7555 6.92522 11.4645 6.63043 11.1055 6.63043H11.1053Z" fill="white"/>
            </svg>
        </a>
    </div>
    <div v-else-if="type == 'popup'">
        <button @click="open_form" class="btn" :class="color">
            <span>{{ cta.text }}</span>
        </button>
    </div>
    <div v-else>
        <router-link :to="{ name: 'Challenges' }" class="btn" :class="color">
            <span>{{ cta.text }}</span>
        </router-link>
    </div>
</template>


<script>
export default {
    props: ["cta", "color", "size", "type"],
    methods: {
        open_form(){
            document.getElementById('home').classList.add('small-opacity');
            document.body.classList.add('no-scroll');
            this.$store.state.display_form_popup = true;
        }
    }
}
</script>


<style scoped>
    .btn{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6rem;
        padding: 2.7rem 3rem;
        width: 27.1rem;
        max-width: 100%;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
        transition: all ease 0.4s;
        border: none;
        box-sizing: border-box;
    }

    .btn.black{
        background: var(--black);
        color: var(--white);
    }

    .btn.white{
        background: var(--white);
        color: var(--black);
    }

    .btn svg{
        margin-left: 1.5rem;
        width: 1.5rem;
    }

    .btn-large{
        width: 100%;
        max-width: 33.5rem;
        border: solid var(--white) 0.3rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @media screen and (min-width: 992px){
        .btn{
            padding-left: 5.5rem;
            padding-right: 5.5rem;
            width: max-content;
            max-width: none;
        }

        .btn:hover{
            transform: translateY(-0.5rem);
        }

        .btn.black:hover{
            box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.35);
        }

        .btn-large{
            font-size: 5.3rem;
            padding: 4.4rem 8.4rem;
            border-width: 0.4rem;
            border-radius: 17.7rem;
        }

        .btn-large svg{
            width: 5.5rem;
            margin-left: 2rem;
        }

        .btn-large svg path{
            transition: all ease 0.4s;
        }

        .btn-large:hover{
            background: var(--white);
            color: var(--black);
            transform: none;
        }

        .btn-large:hover svg path{
            fill: var(--black);
        }
    }
</style>