const animatedOptions = {
    root: null,
    threshold: 0.1, //Pourcentage de l'élément visible avant de déclencher 
    rootMargin: "-20px"
}

const animatedScrollObserver = new IntersectionObserver(
    (entries, animatedScrollObserver) => {
        entries.forEach((entry) => {
            //Si l'élément est dans le viewport:
            if (entry.isIntersecting) {
                entry.target.classList.remove('not-visible');

                //Pour que l'animation ne fonctionne qu'une seule fois, décommenter la ligne suivante
                animatedScrollObserver.unobserve(entry.target);

            //Si l'élément n'est pas dans le viewport
            } else {
                entry.target.classList.add('not-visible');
            }
            
        })
    }, animatedOptions
)


export default {
    mounted (el) {
        animatedScrollObserver.observe(el);
    }
}
