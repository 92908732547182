<template>

    <router-view v-slot="{ Component }">
        <transition name="route" mode="out-in" @beforeLeave="beforeLeave" @afterEnter="afterEnter">
            <component :is="Component"></component>
        </transition>
    </router-view>

</template>

<script>
export default {
    methods:{
        beforeLeave(element){
            element.style.opacity = 0;
        },
        afterEnter(element){
            element.style.opacity = 1;
        }
    }
}
</script>
<style scoped>
    .route-enter-from {
        opacity: 0;
    }
    .route-enter-to,
    .route-leave-from {
        opacity: 1;
    }
    .route-leave-to {
        opacity: 0;
    }
    .route-enter-active,
    .route-leave-active {
        transition: all ease 0.5s;
    }
</style>