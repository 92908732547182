<template>

    <svg class="mobile" viewBox="0 0 374 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M567.395 196.034C567.395 296.568 943.063 502 189.227 502C-479.571 502 -192.943 296.568 -192.943 196.034C-192.943 95.4993 -22.7357 14 187.226 14C397.188 14 567.395 95.4993 567.395 196.034Z" fill="#121212"/>
        <path d="M-146.923 124.324H189.227V320.148H-146.923V124.324Z" fill="#121212"/>
    </svg>

    <svg class="desktop" viewBox="0 0 1602 115" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2814.93 1124.89C2814.93 1738.47 4796.16 2992.27 820.523 2992.27C-2706.63 2992.27 -1194.99 1738.47 -1194.99 1124.89C-1194.99 511.298 -297.341 13.8867 809.971 13.8867C1917.28 13.8867 2814.93 511.298 2814.93 1124.89Z" fill="#121212"/>
        <path d="M-952.288 687.219H820.523V1882.38H-952.288V687.219Z" fill="#121212"/>
    </svg>

</template>

<style scoped>
    svg{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: calc(100% - 1px);
    }
</style>