import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Challenges from '../views/Challenges.vue'

const host = window.location.host
const lang = host.indexOf("bouillon") != -1 ? "fr" : "en"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: lang == "fr" ? 'Dans le bouillon par Canton' : 'In hot broth by Canton',
      description: lang == "fr" ? "Agrémentez vos soirées fondues avec les défis à relever Dans le bouillon par Canton. Canton, les bouillons et les sauces préférées des Québécois pour la fondue." : "Bring out the fun for fondue night with In Hot Broth, a challenge-based game by Canton. Canton is Quebec’s favourite brand for fondue broths and dipping sauces.",
      url: lang == "fr" ? 'https://danslebouillon.cantoncanada.com/' : 'https://inhotbroth.cantoncanada.com/'
    }
  },
  {
    path: '/challenges',
    name: 'Challenges',
    component: Challenges,
    meta: {
      title:  lang == "fr" ? 'Jouez maintenant | Dans le bouillon par Canton' : 'Play now | In hot broth by Canton',
      description: lang == "fr" ? "Jouez dès maintenant à Dans le bouillon par Canton sur navigateur ou mobile. Cinquante défis originaux à relever à table pour agrémenter une soirée fondue." : "Play In Hot Broth now on your browser or mobile device. Fifty original challenges we guarantee will bring out the fun for every fondue night with friends.",
      url: lang == "fr" ? 'https://danslebouillon.cantoncanada.com/challenges' : 'https://inhotbroth.cantoncanada.com/challenges'
    }
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    return { x: 0, y: 0 }
  }
})


router.beforeEach((to, from, next) => {
  document.documentElement.lang = lang;
  document.title = `${to.meta.title}`;
  document.querySelector("meta[name='description']").setAttribute("content", `${to.meta.description}`);
  document.querySelector("meta[property='og:title']").setAttribute("content", `${to.meta.title}`);
  document.querySelector("meta[property='og:url']").setAttribute("content", `${to.meta.url}`);
  document.querySelector("meta[property='og:description']").setAttribute("content", `${to.meta.description}`);
  next();
})


export default router
