<template>
    <div id="footer">

        <FooterShape />

        <div data-scroll data-scroll-speed="0.8">
            <img class="heart" src="../assets/svg/heart.svg" alt="Heart" v-scrollanimation>
            <p v-html="footer.pretitle" class="paragraph2" v-scrollanimation></p>
            <h2 class="title5" v-scrollanimation>{{ footer.title }}</h2>
            <Socials class="my-socials" :socials="footer.socials" />
        </div>


        <Button class="my-button" :cta="footer.website" color="black" size="large" type="external" v-scrollanimation/>

        <section class="last-footer">
            <div class="left">
                <a class="footer-link" :href="footer.links.link1.url" target="_blank">{{ footer.links.link1.text }}</a>
                <a class="footer-link" :href="footer.links.link2.url" target="_blank">{{ footer.links.link2.text }}</a>
                <a class="footer-link" :href="footer.links.link3.url" @click="showSecondLayer">{{ footer.links.link3.text }}</a>
            </div>
            <div class="right">
                {{ footer.last }}
            </div>
        </section>
    </div>
</template>


<script setup>
    import FooterShape from '@/components/FooterShape.vue'
    import Socials from '@/components/Socials.vue'
    import Button from '@/components/Button.vue'

    const props = defineProps(["footer"]);

    function showSecondLayer(){
        UC_UI.showSecondLayer();
    }
</script>


<style scoped>
    #footer{
        position: relative;
        padding: 7rem 2rem 1.9rem;
        background: var(--black);
    }

    .heart{
        display: block;
        width: 8.5rem;
        margin: 0 auto;
        transition: all ease 0.8s;
    }

    .paragraph2{
        text-align: center;
        margin: 4.7rem auto 0;
        max-width: 26rem;
        transition: all ease 0.8s;
        font-family: var(--tertiary-font);
    }

    .title5{
        text-align: center;
        margin: 2rem auto 0;
        transition: all ease 0.8s;
    }

    .my-socials{
        margin: 4.5rem auto 0;
        transition: all ease 0.8s;
    }

    .my-button{
        margin-top: 11rem;
        transition: all ease 0.8s;
    }

    .my-button.not-visible{
        transform: translateY(1rem) scale(0.95);
    }

    .last-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 3rem 2rem 0;
    }

    .left{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .footer-link{
        font-family: var(--forth-font);
        color: var(--white);
        text-decoration: underline;
        font-size: 1.4rem;
        line-height: 2rem;
        padding: 1rem;
        transition: all ease 0.4s;
    }

    .right{
        font-family: var(--forth-font);
        color: var(--white);
        font-size: 1rem;
        line-height: 1.2rem;
        padding-top: 4.1rem;
        text-transform: uppercase;
    }

    @media screen and (min-width: 768px) and (max-width: 991px){
        .paragraph2{
            max-width: 50rem;
        }
    }

    @media screen and (min-width: 992px){
        #footer{
            padding: 7rem 2rem 5rem;
        }

        .heart{
            width: 13.9rem;
        }

        .paragraph2{
            margin-top: 8.5rem;
            max-width: 43rem;
        }

        .title5{
            margin-top: 2.5rem;
        }

        .my-socials{
            margin: 4rem auto 0;
        }

        .my-button{
            margin-top: 21.1rem;
        }

        .my-button.not-visible{
            transform: translateY(2rem) scale(0.95);
        }

        .last-footer{
            flex-direction: row;
            padding: 24.5rem 3.6% 0;
        }

        .left{
            flex-direction: row;
            gap: 4rem;
        }

        .footer-link{
            font-size: 1.8rem;
            line-height: initial;
        }

        .footer-link:hover{
            color: #b6b5b5;
        }

        .right{
            font-size: 1.4rem;
            line-height: initial;
            padding-top: 0;
        }
    }
</style>