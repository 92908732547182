import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/variables.css'
import './assets/styles/main.css'
import './assets/styles/typography.css'
import './assets/styles/animations.css'
import './assets/styles/locomotive.css'
import ScrollAnimation from './directives/scrollanimation'


createApp(App)
    .use(router)
    .use(store)
    .directive("scrollanimation", ScrollAnimation)
    .mount('#app')
