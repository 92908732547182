<template>
    <div id="challenge-entry">

        <div class="ce-title" data-scroll data-scroll-speed="0.8">
            <h2 class="title2" v-html="challengeEntry.title" v-scrollanimation></h2>
        </div> 

        <div class="mushroom-party-content">
            <figure class="fig-mushroom" v-scrollanimation>
                <img class="mushroom mobile" src="../assets/images/mushroom-mobile.png" alt="Mushroom">
                <img class="mushroom desktop" src="../assets/images/mushroom-desktop.png" alt="Mushroom">
            </figure>

            <figure class="fig-party">
                <img class="party" src="../assets/images/party.jpg" alt="Party" v-scrollanimation>
            </figure>
        </div>    

        <div data-scroll data-scroll-speed="0.8" class="btn-container">
            <Button class="button" :cta="challengeEntry.cta" color="white" type="popup" size="small" v-scrollanimation  />
        </div> 

        <figure class="fig-bubbles" v-scrollanimation>
            <img class="bubbles mobile" src="../assets/svg/bubbles.svg" alt="Bubbles">
            <img class="bubbles desktop"  data-scroll data-scroll-speed="-0.5" src="../assets/svg/bubbles-desktop.svg" alt="Bubbles">
        </figure>

    </div>
</template>


<script setup>
    import Button from '@/components/Button.vue'
    import Formulaire from '@/components/Formulaire.vue'

    const props = defineProps(["challengeEntry"]);

    function parallax(){
        window.addEventListener('scroll', function(e) {
            const target = document.querySelectorAll('.scroll');
            var index = 0;
            var length = target.length;

            for (index; index < length; index++) {
                var pos = window.pageYOffset * target[index].dataset.rate;
                if(target[index].dataset.direction === 'vertical') {
                    target[index].style.transform = 'translate3d(0px,' + pos + 'px, 0)';
                } else if(target[index].dataset.direction === 'horizontal') {
                    target[index].style.transform = 'translate3d(' + pos + 'px, 0, 0)';
                } else {
                    var posX = window.pageYOffset * target[index].dataset.ratex;
                    var posY = window.pageYOffset * target[index].dataset.ratey;
                    target[index].style.transform = 'translate3d(' + posX + 'px, ' + posY + 'px, 0px)';
                }
            }
        });
    }

    parallax();
            
</script>


<style scoped>
    #challenge-entry{
        position: relative;
        padding: 9rem 0 9.6rem;
        overflow: hidden;
        background: var(--black);
    }

    .ce-title{
        position: relative;
        z-index: 9;
    }

    .title2{
        position: relative;
        padding-left: 3.5rem;
        padding-right: 32%;
        z-index: 9;
        transition: all ease 0.8s;
        padding-top: 2rem;
        margin-top: -2rem;
    }

    img{
        width: 100%;
        max-width: none;
    }

    .mushroom-party-content{
        position: relative;
    }

    .fig-mushroom{
        position: absolute;
        bottom: calc(100% + 4rem);
        right: 0;
        width: 30vw;
        transition: all ease 0.8s;
    }

    .fig-party{
        position: relative;
        margin-top: 5rem;
        width: 80%;
        margin-left: 25%;
    }

    .party{
        width: 100%;
        padding: 3% 3% 10%;
        transform: rotate(-5deg);
        background: var(--white);
        transition: all ease 0.8s;
    }

    .party.not-visible{
        opacity: 0;
        transform: translateY(1rem) rotate(-10deg);
    }

    .fig-bubbles{
        position: absolute;
        left: -10.4rem;
        bottom: 17.7rem;
        transition: all ease 2s;
    }

    .fig-bubbles.not-visible{
        opacity: 0;
        transform: translateY(1rem) scale(0.9);
    }

    .bubbles{
        width: 20.4rem;
    }

    .btn-container{
        position: relative;
        z-index: 9;
    }

    .button{
        position: relative;
        margin-top: 7rem;
        transition: all ease 0.8s;
    }

    .button.not-visible{
        opacity: 0.5;
        transform: translateY(1rem) scale(0.95);
    }


    @media screen and (min-width: 768px) and (max-width: 991px){

        #challenge-entry{
            padding: 12rem 0 10rem;
        }

        .fig-party{
            padding-top: 8rem;
        }

        .fig-mushroom{
            max-width: 20rem;
        }

        .title2{
            padding-left: 5rem;
            padding-right: 25rem;
        }

        .button{
            margin-top: 12rem;
        }
    }

    @media screen and (min-width: 992px){

        #challenge-entry{
            padding: 0 0 14.7rem;
        }

        .title2{
            padding: 0 55% 0 13%;
            margin-top: 21rem;
            padding-top: 3rem;
        }

        .title2.not-visible{
            transform: translateY(2rem);
        }

        .mushroom-party-content{
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 40%;
            top: 0;
            right: 12.8%;
            max-width: 80rem;
        }

        .fig-mushroom{
            position: relative;
            bottom: initial;
            top: 0;
            right: 0;
            width: 26vw;
        }

        .fig-mushroom.not-visible{
            transform: translateY(2rem);
        }

        .fig-party{
            position: relative;
            margin-top: 5rem;
            width: 80%;
            margin-left: 0;
        }

        .party{
            width: 100%;
            padding: 5% 5% 15%;
            transform: rotate(-5deg);
            background: var(--white);
        }

        .party.not-visible{
            transform: translateY(2rem) rotate(-10deg);
        }

        .fig-bubbles{
            position: relative;
            left: initial;
            bottom: initial;
        }

        .fig-bubbles.not-visible{
            transform: translateY(2rem);
        }


        .button{
            margin-top: 8rem;
            margin-left: 13%;
            width: max-content;
        }

        .button.not-visible{
            transform: translateY(2rem) scale(0.9);
        }

        .bubbles{
            width: 23.7rem;
            padding-left: 25%;
            padding-top: 2rem;
        }
    }
</style>