<template>
    <div id="hero">

        <section class="title-content">
            <div class="main-logo main-logo-fr" v-if="hero.lang == 'fr'">
                <img class="main-logo-text" src="../assets/svg/logo-fr.svg" alt="Logo - Dans le bouillon">
                <img class="smiley" src="../assets/svg/smiley.svg" alt="Smiley">
            </div>
            <div class="main-logo main-logo-en" v-else>
                <img class="main-logo-text" src="../assets/svg/logo-en.svg" alt="Logo - In hot broth">
                <img class="smiley" src="../assets/svg/smiley.svg" alt="Smiley">
            </div>
            <img v-if="hero.lang == 'fr'" class="canton-logo cl-fr" src="../assets/svg/canton-logo-fr.svg" alt="Canton logo">
            <img v-else class="canton-logo cl-en" src="../assets/svg/canton-logo-en.svg" alt="Canton logo">
        </section>

        <section class="assets">
            <div class="ingredients">
                <img class="broccoli mobile scroll" src="../assets/images/broccoli-mobile.png" alt="Broccoli" data-direction="vertical" data-rate="-0.05">
                <img class="shrimp mobile scroll" src="../assets/images/shrimp-mobile.png" alt="Shrimp" data-direction="vertical" data-rate="-0.1">
                
                <img class="broccoli desktop" data-scroll data-scroll-speed="0.8" src="../assets/images/broccoli-desktop.png" alt="Broccoli" data-direction="vertical" data-rate="2">
                <img class="shrimp desktop" data-scroll data-scroll-speed="1.8" src="../assets/images/shrimp-desktop.png" alt="Shrimp" data-direction="vertical" data-rate="-0.1">
            </div>
            <lottie-animation data-scroll data-scroll-speed="1.1" class="fire" path="fire.json" />
            <img class="saucepan mobile" data-scroll data-scroll-speed="1.3" src="../assets/images/saucepan-mobile.png" alt="Saucepan">
            <img class="saucepan desktop" data-scroll data-scroll-speed="1.5" src="../assets/images/saucepan-desktop.png" alt="Saucepan">
        </section>

        <div class="arrow-container scroll" data-direction="vertical" data-rate="-0.05">
            <img class="arrow" src="../assets/svg/arrow.svg" alt="Arrow">
        </div>

        <div v-if="windowWidth < 992" v-html="hero.rules" class="rules scroll" data-direction="vertical" data-rate="-0.05"></div>
        <div v-else v-html="hero.rules" class="rules" data-scroll data-scroll-sticky data-scroll-target="#app"></div>
    </div>
</template>


<script setup>
    import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

    const props = defineProps(["hero"]);

    const windowWidth = window.innerWidth;
</script>


<style scoped>
    #hero{
        position: relative;
        overflow: hidden;
        padding: 8.1rem 0 3.3rem;
    }

    .title-content{
        text-align: center;
    }

    .main-logo{
        position: relative;
        margin: 0 auto;
        transform: translateX(0.75rem);
        animation: custom_reveal_bottom 1s ease 0.2s both;
    }

    .main-logo-fr .main-logo-text{
        width: 81%;
    }

    .main-logo-fr .smiley{
        position: absolute;
        bottom: 4%;
        left: 62.5%;
        width: 15%;
        animation: rotation 6s infinite linear;
    }

    .main-logo-en .main-logo-text{
        width: 59%;
    }

    .main-logo-en .smiley{
        position: absolute;
        bottom: 5.5%;
        left: 43%;
        width: 15%;
        animation: rotation 6s infinite linear;
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }

    .canton-logo{
        padding-top: 1.8rem;
        width: 42%;
        animation: custom_reveal_bottom 1s ease 0.4s both;
    }

    .assets{
        position: relative;
        width: 100%;
        transform: translateY(-4%);
    }

    .ingredients{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: -1.5rem;
    }

    .shrimp {
        width: 22%;
        z-index: -1;
        animation: custom_reveal_bottom 1.5s ease 0.5s both;
    }

    .broccoli{
        width: 37%;
        padding-top: 3.1rem;
        animation: custom_reveal_bottom 1.5s ease 0.7s both;
    }

    .fire{
        width: 70%!important;
        animation: custom_reveal_bottom 1s ease 0.8s both;
    }

    .saucepan{
        position: relative;
        width: 86%;
        margin-top: -17%;
        margin-left: -3%;
        animation: custom_reveal_opacity 1s ease 0.8s both;
    }

    .arrow{
        display: block;
        margin: 0 auto;
        animation: bounce-top 2.5s infinite both;
    }

    @keyframes bounce-top {
        0% {
            transform: translateY(-22.5px);
            animation-timing-function: ease-in;
        }
        12% {
            opacity: 1;
        }
        20% {
            transform: translateY(-12px);
            animation-timing-function: ease-in;
        }
        33% {
            transform: translateY(-6px);
            animation-timing-function: ease-in;
        }
        41% {
            transform: translateY(-3px);
            animation-timing-function: ease-in;
        }
        46% {
            transform: translateY(-2px);
            animation-timing-function: ease-in;
        }
        12%,
        27%,
        37%,
        43% {
            transform: translateY(0px);
            animation-timing-function: ease-out;
        }
        50% {
            transform: translateY(0px);
            animation-timing-function: ease-out;
            opacity: 1;
        }
        85% {
            transform: translateY(0px);
            animation-timing-function: ease-out;
            opacity: 1;
        }
        100% {
            transform: translateY(-22.5px);
            animation-timing-function: ease-in;
        }
    }


    .rules{
        font-family: var(--forth-font);
        color: var(--white);
        font-size: 1.8rem;
        line-height: 2.2rem;
        padding: 3.7rem 4.2rem 0;
        text-align: center;
        max-width: 30rem;
        margin: 0 auto;
        animation: custom_reveal_bottom 1.5s ease 1.1s both;
    }

    @media screen and (min-width: 992px){
        #hero{
            padding: 12.1rem 0 2.6rem;
        }

        .main-logo{
            transform: translateX(1.5rem);
        }

        .main-logo-fr .main-logo-text{
            width: 48%;
        }

        .main-logo-fr .smiley{
            left: 57.3%;
            bottom: 2%;
            width: 9%;
        }

        .main-logo-en .main-logo-text{
            width: 35%;
        }

        .main-logo-en .smiley{
            left: 46%;
            bottom: 3%;
            width: 9%;
        }

        .canton-logo{
            padding-top: 2.5rem;
            width: 15%;
        }

        .assets{
            transform: none;
        }

        .ingredients{
            margin-top: -16%;
        }

        .broccoli{
            width: 27%;
            padding-top: 13.7rem;
        }

        .shrimp {
            width: 23.6%;
        }

        .fire{
            margin-top: -9%!important;
            width: 45%!important;
        }

        .saucepan{
            width: 63%;
            margin-top: -11%;
            margin-left: 6%;
        }

        .arrow-container,
        .arrow{
            display: none;
        }

        .rules{
            position: fixed;
            top: calc(100vh - 20rem);
            right: 5rem;
            padding: 2.4rem 3.3rem;
            text-align: left;
            max-width: none;
            width: 15%;
            margin: initial;
            border: 2px solid var(--white);
            border-radius: 2rem;
            z-index: -1;
        }
    }
</style>